<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-no-padding ion-padding-bottom" :fullscreen="true" :scroll-y="false">
      <div class="a-auth full-height">
        <div class="ion-padding a-auth__content-wrapper">
          <h1>Reset password</h1>
          <ion-item class="ion-no-padding ion-padding-top" :style="valid(v.code)">
            <ion-label position="floating">Code</ion-label>
            <ion-input
              v-model="code"
              class="ion-padding-vertical"
              name="code"
              type="text"
              @ionBlur="onBlur(v.code)"
            ></ion-input>
          </ion-item>
          <ion-item class="ion-no-padding ion-padding-top" :style="valid(v.email)">
            <ion-label position="floating">Email</ion-label>
            <ion-input
              v-model="email"
              class="ion-padding-vertical"
              name="code"
              type="email"
              @ionBlur="onBlur(v.email)"
            ></ion-input>
          </ion-item>
          <ion-item class="ion-no-padding ion-padding-top" :style="valid(v.password)">
            <ion-label position="floating">Password</ion-label>
            <ion-input
              v-model="password"
              class="ion-padding-vertical"
              name="password"
              type="password"
              @ionBlur="onBlur(v.password)"
            ></ion-input>
          </ion-item>
          <ion-item class="ion-no-padding ion-padding-top" :style="valid(v.confirmPassword)">
            <ion-label position="floating">Confirm password</ion-label>
            <ion-input
              v-model="confirmPassword"
              class="ion-padding-vertical"
              name="password"
              type="password"
              @ionBlur="onBlur(v.confirmPassword)"
            ></ion-input>
          </ion-item>

          <div v-if="isLoading" class="load-wrap ion-text-center">
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <p>Loading...</p>
          </div>
          <ion-button v-else :disabled="isFormInvalid" class="--default a-auth__button ion-margin-vertical" @click="reset">Reset my password</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
  import useVuelidate from '@vuelidate/core'
  import { required, sameAs, email } from "@vuelidate/validators"
  import { presentToast } from '@/plugins/w/ionic-toast/index'
  import {mapGetters} from 'vuex'

  export default {

    data() {
      return {
        email: '',
        code: '',
        password: '',
        confirmPassword: '',
        v: useVuelidate()
      }
    },

    mounted() {
      this.code = this.$route.query.code
      this.email = this.$route.query.email
    },

    validations() {
      return {
        email: { required, email },
        code: { required },
        password: { required },
        confirmPassword: { sameAsPassword: sameAs(this.password)}
      }
    },

    computed: {
      isFormInvalid() {
        return this.v.password.$invalid || this.v.confirmPassword.$invalid || this.v.code.$invalid
      },
      ...mapGetters('wAuth', ['isLoading']),
    },

    methods: {
      back() {
        this.$router.go(-1)
      },

      async reset() {
        const isValid = await this.v.$validate()
        if (!isValid)
          return

        try {
          await this.$store.dispatch('wAuth/resetPassword', {
            email: this.email,
            code: this.code,
            password: this.password,
            password_confirmation: this.confirmPassword
          })
          presentToast('Password change successful', 'success')
          this.$router.push('login')
        } catch (error) {
          console.error(error.response)
          presentToast(error.response.data.error, 'danger')
        }
      },

      valid(input) {
        return input.$dirty && input.$invalid
          ? { '--border-color': 'var(--highlight-color-invalid)' }
          : {}
      },

      onBlur(element) {
        element.$touch()
      }
    }
  }
</script>

<style lang="sass" scoped>
ion-header, ion-toolbar
  --background: transparent
</style>
