
  import useVuelidate from '@vuelidate/core'
  import { required, sameAs, email } from "@vuelidate/validators"
  import { presentToast } from '@/plugins/w/ionic-toast/index'
  import {mapGetters} from 'vuex'

  export default {

    data() {
      return {
        email: '',
        code: '',
        password: '',
        confirmPassword: '',
        v: useVuelidate()
      }
    },

    mounted() {
      this.code = this.$route.query.code
      this.email = this.$route.query.email
    },

    validations() {
      return {
        email: { required, email },
        code: { required },
        password: { required },
        confirmPassword: { sameAsPassword: sameAs(this.password)}
      }
    },

    computed: {
      isFormInvalid() {
        return this.v.password.$invalid || this.v.confirmPassword.$invalid || this.v.code.$invalid
      },
      ...mapGetters('wAuth', ['isLoading']),
    },

    methods: {
      back() {
        this.$router.go(-1)
      },

      async reset() {
        const isValid = await this.v.$validate()
        if (!isValid)
          return

        try {
          await this.$store.dispatch('wAuth/resetPassword', {
            email: this.email,
            code: this.code,
            password: this.password,
            password_confirmation: this.confirmPassword
          })
          presentToast('Password change successful', 'success')
          this.$router.push('login')
        } catch (error) {
          console.error(error.response)
          presentToast(error.response.data.error, 'danger')
        }
      },

      valid(input) {
        return input.$dirty && input.$invalid
          ? { '--border-color': 'var(--highlight-color-invalid)' }
          : {}
      },

      onBlur(element) {
        element.$touch()
      }
    }
  }
